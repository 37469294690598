import { useEffect, useState } from "react";
import Department from "../../interfaces/department";
import { DepartmentService } from "./department.service";


function useDepartment() {
    const [departments, setDepartments] = useState<Department[] | undefined>();

    useEffect(() => {
        async function fetch() {
            let data: Department[] = await DepartmentService.fetch();
            setDepartments (data)
        }

        if (typeof departments === 'undefined') {fetch ()}
    }, [departments]);


    return {
        departments: departments,
        chargementsDepartments: !!departments
    }
}

export default useDepartment;
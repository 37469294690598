import axios from 'axios';
import CreateMembreDto from '../../interfaces/dto/createmembreDto';
import { MEMBRE_API_URL } from '../Api-end-points';



class MembrService {
    static async fetch() {
        return await axios.get(`${MEMBRE_API_URL}`).then((res) => res.data);
    }

    static async ajouterMembre(membre: CreateMembreDto) {
        var formData = new FormData();

        formData.append('civilite', membre.civilite)
        formData.append('firstname', membre.firstname)
        formData.append('lastname', membre.lastname)
        formData.append('reference', membre.reference)
        formData.append('qualifications', membre.qualifications)
        formData.append('profession', membre.profession)
        formData.append('contact', membre.contact)
        formData.append('type_contact', membre.type_contact)
        formData.append('autre_contact', membre.autre_contact)
        formData.append('department_id', membre.department_id.toString())
        formData.append('cellule_id', membre.cellule_id.toString())
        formData.append('photo', membre.photo?.originFileObj, membre.photo?.originFileObj?.name)

        return await axios.post(`${MEMBRE_API_URL}`, formData).then((res) => res.data).catch((error) => error);
    }
}
export default MembrService;
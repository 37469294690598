import { useEffect, useState } from "react";
import { Membre } from "../../interfaces/membre";
import MembrService from "./membre-actions";


function useMembre() {
    const [membres, setMembres] = useState<Membre[] | undefined>();

    useEffect(() => {
        async function fetch() {
            let data: Membre[] = await MembrService.fetch();
            setMembres (data)
        }

        if (typeof membres === 'undefined') {fetch ()}
    }, [membres]);


    return {
        membres: membres,
        chargementsMembre: !!membres
    }
}

export default useMembre;
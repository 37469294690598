import { useEffect, useState } from "react";
import Cellule from "../../interfaces/cellule";
import { CelluleService } from "./cellule.service";


function useCellule() {
    const [cellules, setCellules] = useState<Cellule[] | undefined>();

    useEffect(() => {
        async function fetch() {
            let data: Cellule[] = await CelluleService.fetch();
            setCellules(data)
        }

        if (typeof cellules === 'undefined') {fetch ()}
    }, [cellules]);


    return {
        cellules: cellules,
        chargementsCellule: !!cellules
    }
}

export default useCellule;

export const TYPE_CONTACT = [
    {
        id : 1,
        label : 'Aucun'
    },
    {
        id : 2,
        label : 'Whatsapp'
    },
    {
        id : 3,
        label : 'Telegram'
    },
];


export const CIVILITES = [
    {
        id : 1,
        value : 'M.',
        label : 'Monsieur'
    },
    {
        id : 2,
        value : 'Mme',
        label : 'Madame',
    },
    {
        id : 3,
        value : 'Mlle',
        label : 'Mademoiselle',
    },
    {
        id : 4,
        value : 'Dr',
        label : 'Docteur',
    },
    {
        id : 5,
        value : 'Pr',
        label : 'Professeur',
    },
]


// export const BASE_URL = "http://localhost:8000/api";
export const BASE_URL = "http://backend.chaka-brenda.com/api";
export const STORAGE_URL = "http://backend.chaka-brenda.com";
export const COMMUNE_API_URL = `${BASE_URL}/commune`;
export const FAMILLE_API_URL = `${BASE_URL}/famille`;

export const NIVEAU_API_URL = `${BASE_URL}/niveau`;

export const CELLULE_API_URL = `${BASE_URL}/cellules`;
export const DEPARTMENT_API_URL = `${BASE_URL}/departments`;
export const MEMBRE_API_URL = `${BASE_URL}/membre`;
export const UPLOADS_API_URL = `${BASE_URL}/uploads`;


import React, { useState, useEffect } from 'react';
import './App.css';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from 'antd/es/layout/layout';
import { Container, Col, Row } from 'react-bootstrap';
import { Space, Typography, Button, Input, Select, message, Upload, DatePicker, notification } from 'antd';
import { FacebookOutlined, LoadingOutlined, PlusOutlined, SaveFilled, YoutubeOutlined } from '@ant-design/icons';
import * as Yup from 'yup'
import styled from 'styled-components';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/es/upload';
import Logo from './logo.png';
import Facebook from './Telecharger-Facebook.webp';
import Youtube from './Telecharger-YouTube.webp';
import Watsapp from './unnamed.png';


import {
  Formik,
  Form,
  Field,
} from 'formik';
import MembrService from './data/membre/membre-actions';
import { CIVILITES, TYPE_CONTACT } from './shared/helpers/helpers';
import CreateMembreDto from './interfaces/dto/createmembreDto';
import { UPLOADS_API_URL } from './data/Api-end-points';
import TextArea from 'antd/es/input/TextArea';
import useCellule from './data/cellule/useCellule';
import useDepartment from './data/department/useDepartment';


const { Title } = Typography;

const FormError = styled.small`
  color : red;
`;


export const InputItemField = styled(Input)`
  height: 40px;
  border-radius : 5px;
  margin-top : 5px;
  border-radius : 0;
`;

export const DatePickerItemField = styled(DatePicker)`
  height: 40px;
  border-radius : 5px;
  margin-top : 5px;
  border-radius : 0;
`;

export const SelectItemField = styled(Select)`
  width : 100%;
  .ant-select-selector {
    border-radius : 5px !important;
    margin-top : 5px;
    width : 100%;
    height: 40px !important;
    border-radius : 0 !important;
  }
  
`;

const { Option } = Select



const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error('Image must smaller than 2MB!');
  // }
  return isJpgOrPng;
};


function App() {

  const [chargement, setChargement] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile>();
  const { cellules } = useCellule();
  const { departments } = useDepartment();


  const handleChangeUpload: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setFileList(info.file)
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Photo</div>
    </div>
  );

  const validationSchema = Yup.object().shape({
    civilite: Yup.string().required("La civilite est requis"),
    firstname: Yup.string().required("Le nom est requis"),
    lastname: Yup.string().required("Le prenom est requis"),
    cellule_id: Yup.string().required("La commune est requis"),
    reference: Yup.string().required("La Commune/Quartier est requis"),
    contact: Yup.string().required("La contact est requis"),
    type_contact: Yup.string().required("Le type contact est requis"),
    qualifications: Yup.string().required("La qualifications est requis"),
    profession: Yup.string().required("La profession est requis"),
    department_id: Yup.string().required("Le niveau d'etude est requis"),
  });


  const handleSubmitMembre = (values: CreateMembreDto) => {
    setChargement(true)
    values.photo = fileList;
    MembrService.ajouterMembre(values).then((res) => {
      notification.success({
        message: 'Succès !',
        description: 'Votre opération a été effectuée avec succès !'
      });

      setTimeout(() => {
        setChargement(false);
        window.location.reload();
      }, 3000)
    });
  }

  const handleCheckPhoto = () => {
    notification.error({
      message: 'Error !',
      description: 'Veuillez sélectionnez une photo svp !',
      placement: 'bottomRight'
    });
  }


  return (
    <React.Fragment>
      <Header>
        <Container>
          <div className='d-flex justify-content-between align-items-center h-100'>
            <span className='logo'>
              <Space>
                <img src={Logo} alt="" />
                <div className='logoTitle'>
                  <div> Eglise des Assemblées </div>
                  <div>  de Dieu Dokui extention Péniel </div>
                </div>
              </Space>
            </span>
            <Space>
              <div className='icon Facebook'><a href="https://sw-ke.facebook.com/100077492473008/photos/" target='_blank'><img src={Facebook} alt="" /></a></div>
              <div className='icon Youtube'><a href="https://www.youtube.com/channel/UCVMQxLyZuY0XmVZdmwFPuIQ" target='_blank'><img src={Youtube} alt="" /></a></div>
              <div className='icon Watsapp'><a href=""><img src={Watsapp} alt="" /></a></div>
            </Space>
          </div>
        </Container>
      </Header>
      <main>
        <Container fluid>
          <div className="row justify-content-center">
            <div className="col-md-7">
              <div>
                <Title className='title-head'> BIENVENUE SUR LA PLATEFORME <br /> DE RECENSEMENT</Title>
                <span>
                  Veuillez remplir les différents champs du formulaire <br />
                  <strong>NB:</strong> les libellés suivies de <sup style={{ color: 'red' }}>(*)</sup> sont obligatoire
                </span>
              </div>
            </div>
          </div>
          <section>
            <Formik
              initialValues={{
                firstname: '',
                civilite: '',
                lastname: '',
                department_id: '',
                reference: '',
                contact: '',
                type_contact: '',
                autre_contact: '',
                cellule_id: '',
                qualifications: '',
                profession: '',
              }}
              onSubmit={(values: CreateMembreDto) => typeof fileList !== 'undefined' ? handleSubmitMembre(values) : handleCheckPhoto ()}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, handleSubmit, errors, touched, setFieldValue }) => (
                <Form>
                  <Row className='justify-content-center'>
                    <Col md="7" xs="12" className='bg-light'>
                      <Row className='d-flex justify-content-center align-items-center' style={{ marginTop: '1rem' }}>
                        <div>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={`${UPLOADS_API_URL}`}
                            beforeUpload={beforeUpload}
                            onChange={handleChangeUpload}
                          >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                          </Upload>
                        </div>
                      </Row>
                      <Row>
                        <Col md="4" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName">Civilité <sup style={{ color: 'red' }}>(*)</sup></label>
                          <SelectItemField
                            allowClear
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) =>
                              typeof option !== 'undefined' &&
                              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                            onChange={(value, option) => {
                              option !== null
                                ? setFieldValue('civilite', value)
                                : setFieldValue('civilite', null)
                            }}

                          >
                            {CIVILITES && CIVILITES.map((item) =>
                              <Option
                                key={item.id}
                                value={item.value}
                                name={item.label}
                              >
                                {item.label}
                              </Option>)}
                          </SelectItemField>
                          <FormError>
                            {touched.civilite && errors.civilite && <div>{errors.civilite}</div>}
                          </FormError>
                        </Col>

                        <Col md="4" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName">Nom <sup style={{ color: 'red' }}>(*)</sup></label>
                          <Field
                            as={InputItemField}
                            value={values.firstname}
                            onChange={handleChange('firstname')}
                            type="text"
                          />
                          <FormError>
                            {touched.firstname && errors.firstname && <div>{errors.firstname}</div>}
                          </FormError>
                        </Col>

                        <Col md="4" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName">Prénom(s) <sup style={{ color: 'red' }}>(*)</sup></label>
                          <Field
                            as={InputItemField}
                            value={values.lastname}
                            onChange={handleChange('lastname')}
                            type="text"
                          />
                          <FormError>
                            {touched.lastname && errors.lastname && <div>{errors.lastname}</div>}
                          </FormError>
                        </Col>


                        <Col md="4" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName">Commune/Quartier <sup style={{ color: 'red' }}>(*)</sup></label>
                          <Field
                            as={InputItemField}
                            value={values.reference}
                            onChange={handleChange('reference')}
                            type="text"
                          />
                          <FormError>
                            {touched.reference && errors.reference && <div>{errors.reference}</div>}
                          </FormError>
                        </Col>


                        <Col md="4" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName">Département <sup style={{ color: 'red' }}>(*)</sup></label>
                          <SelectItemField
                            allowClear
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) =>
                              typeof option !== 'undefined' &&
                              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                            onChange={(value, option) => {
                              option !== null
                                ? setFieldValue('department_id', value)
                                : setFieldValue('department_id', null)
                            }}

                          >
                            {departments && departments.map((item) =>
                              <Option
                                key={item.id}
                                value={item.id}
                                name={item.libelle_dep}
                              >
                                {item.libelle_dep}
                              </Option>)}
                          </SelectItemField>
                          <FormError>
                            {touched.department_id && errors.department_id && <div>{errors.department_id}</div>}
                          </FormError>
                        </Col>

                        <Col md="4" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName">Cellule <sup style={{ color: 'red' }}>(*)</sup></label>
                          <SelectItemField
                            allowClear
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) =>
                              typeof option !== 'undefined' &&
                              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                            onChange={(value, option) => {
                              option !== null
                                ? setFieldValue('cellule_id', value)
                                : setFieldValue('cellule_id', null)
                            }}

                          >
                            {cellules && cellules.map((item) =>
                              <Option
                                key={item.id}
                                value={item.id}
                                name={item.libelle_cel}
                              >
                                {item.libelle_cel}
                              </Option>)}
                          </SelectItemField>
                          <FormError>
                            {touched.cellule_id && errors.cellule_id && <div>{errors.cellule_id}</div>}
                          </FormError>
                        </Col>


                        <Col md="4" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName"> Contact <sup style={{ color: 'red' }}>(*)</sup></label>
                          <Field
                            as={InputItemField}
                            value={values.contact}
                            onChange={handleChange('contact')}
                            type="text"
                          />
                          <FormError>
                            {touched.contact && errors.contact && <div>{errors.contact}</div>}
                          </FormError>
                        </Col>



                        <Col md="4" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName">Type de contact <sup style={{ color: 'red' }}>(*)</sup></label>
                          <SelectItemField
                            allowClear
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) =>
                              typeof option !== 'undefined' &&
                              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                            onChange={(value, option) => {
                              option !== null
                                ? setFieldValue('type_contact', value)
                                : setFieldValue('type_contact', null)
                            }}

                          >
                            {TYPE_CONTACT.map((item) =>
                              <Option
                                value={item.id}
                                key={item.id}
                                name={item.label}
                              >
                                {item.label}
                              </Option>)}
                          </SelectItemField>
                          <FormError>
                            {touched.type_contact && errors.type_contact && <div>{errors.type_contact}</div>}
                          </FormError>
                        </Col>


                        <Col md="4" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName">Autre Contact</label>
                          <Field
                            as={InputItemField}
                            value={values.autre_contact}
                            onChange={handleChange('autre_contact')}
                            type="text"
                          />

                        </Col>



                        <Col md="6" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName"> Qualification <sup style={{ color: 'red' }}>(*)</sup></label>
                          <Field
                            as={TextArea}
                            value={values.qualifications}
                            onChange={handleChange('qualifications')}
                            type="text"
                          />
                          <FormError>
                            {touched.qualifications && errors.qualifications && <div>{errors.qualifications}</div>}
                          </FormError>
                        </Col>


                        <Col md="6" style={{ marginTop: '1rem' }}>
                          <label htmlFor="firstName">Activité ou Profession <sup style={{ color: 'red' }}>(*)</sup></label>
                          <Field
                            as={TextArea}
                            value={values.profession}
                            onChange={handleChange('profession')}
                            type="text"
                          />
                          <FormError>
                            {touched.profession && errors.profession && <div>{errors.profession}</div>}
                          </FormError>
                        </Col>

                        <Col md="12" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                          <div className='d-flex justify-content-center'>
                            <Button
                              type='default'
                              size='large'
                              style={{
                                marginTop: '10px',
                                marginRight: '10px',
                                borderRadius: 0,
                              }}
                              className='btn-default'
                            >
                              ANNULER
                            </Button>

                            <Button
                              danger
                              size='large'
                              type='primary'
                              style={{
                                marginTop: '10px',
                                borderRadius: 0,
                                background: '#f95917'
                              }}
                              onClick={() => handleSubmit()}
                              loading={chargement}
                              disabled={chargement}
                            >
                              <strong>SOUMETTRE LES INFORMATIONS</strong>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </section>
        </Container>
      </main>
      <footer>
        <Container fluid>
          <div className='d-flex'>
            <span className='text-white'>  <strong>© 2023 AD DOKUI PENIEL Tous droits réservés, Développé par la CCRP</strong> </span>
          </div>
        </Container>
      </footer>
    </React.Fragment >
  );
}

export default App;

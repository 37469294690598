import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Header } from 'antd/es/layout/layout';
import { Space, Table, Tag } from "antd";
import Logo from './logo.png';
import Facebook from './Telecharger-Facebook.webp';
import Youtube from './Telecharger-YouTube.webp';
import Watsapp from './unnamed.png';
import useMembre from "./data/membre/useMembre";
import Title from "antd/es/typography/Title";
import { Membre } from "./interfaces/membre";
import { STORAGE_URL } from "./data/Api-end-points";



const MembreScreen = () => {
    const { membres } = useMembre();

    useEffect(() => {
        console.log(membres)
    }, [membres])


    const columns = [
        {
            title: '#',
            dataIndex: 'photo',
            key: 'photo',
            render: (text: any, record: Membre) => <>
                <span> <img src={`${STORAGE_URL}/uploads/${record.photo}`} alt="" style={{ width: '50px' }} /> </span>
            </>
        },
        {
            title: 'Nom & prénom(s)',
            dataIndex: 'lastname',
            key: 'lastname',
            render: (text: any, record: Membre) => <>
                <span> {record.civilite} {record.firstname} {record.lastname} </span>
            </>
        },
        {
            title: 'Commune/Quartier',
            dataIndex: 'reference',
            key: 'reference',
            render: (text: any, record: Membre) => <>
                <span> {record.reference}</span>
            </>
        },
        {
            title: 'Département',
            dataIndex: 'department',
            key: 'department',
            render: (text: any, record: Membre) => <>
                <span> {record.department_id}</span>
            </>
        },
        {
            title: 'Cellule',
            dataIndex: 'cellule',
            key: 'cellule',
            render: (text: any, record: Membre) => <>
                <span> {record.cellule_id}</span>
            </>
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            key: 'contact',
            render: (text: any, record: Membre) => <>
                <span> {record.contact}</span>
            </>
        },
        {
            title: 'Type contact',
            dataIndex: 'type_contact',
            key: 'type_contact',
            render: (text: any, record: Membre) => <>
                <Tag color={record.type_contact == "Telegramm" ? "blue" : "green"}><span> {record.type_contact}</span></Tag>
            </>
        },
        {
            title: 'Autre contact',
            dataIndex: 'autre_contact',
            key: 'autre_contact',
            render: (text: any, record: Membre) => <>
                <span> {record.autre_contact}</span>
            </>
        },
        {
            title: 'Qualification',
            dataIndex: 'qualifications',
            key: 'qualifications',
            render: (text: any, record: Membre) => <>
                <span> {record.qualifications}</span>
            </>
        },
        {
            title: 'Profession',
            dataIndex: 'profession',
            key: 'profession',
            render: (text: any, record: Membre) => <>
                <span> {record.profession}</span>
            </>
        },
    ];


    return (
        <React.Fragment>
            <Header>
                <Container>
                    <div className='d-flex justify-content-between align-items-center h-100'>
                        <span className='logo'>
                            <Space>
                                <img src={Logo} alt="" />
                                <div className='logoTitle'>
                                    <div> Eglise des Assemblées </div>
                                    <div>  de Dieu Dokui extention Péniel </div>
                                </div>
                            </Space>
                        </span>
                        <Space>
                            <div className='icon Facebook'><a href="https://sw-ke.facebook.com/100077492473008/photos/" target='_blank'><img src={Facebook} alt="" /></a></div>
                            <div className='icon Youtube'><a href="https://www.youtube.com/channel/UCVMQxLyZuY0XmVZdmwFPuIQ" target='_blank'><img src={Youtube} alt="" /></a></div>
                            <div className='icon Watsapp'><a href=""><img src={Watsapp} alt="" /></a></div>
                        </Space>
                    </div>
                </Container>
            </Header>
            <main>
                <Container>
                    <div className="row justify-content-center">
                        <div>
                            <div>
                                <Title className='title-head'> BIENVENUE SUR LA PLATEFORME <br /> DE RECENSEMENT</Title>
                            </div>
                        </div>
                    </div>
                    <section>
                        <Table dataSource={membres && membres} columns={columns} />
                        
                    </section>
                </Container>
            </main>
            <footer>
                <Container fluid>
                    <div className='d-flex'>
                        <span className='text-white'>  <strong>© 2023 AD DOKUI PENIEL Tous droits réservés, Développé par la CCRP</strong> </span>
                    </div>
                </Container>
            </footer>
        </React.Fragment >

    );
}

export default MembreScreen;